/* Modals */

.modal-dialog{
  margin-top: 50px;
  margin-bottom: 50px;
  .modal-content{
    border: 1px solid $modal-border-color;
    .modal-header{
      padding: $modal-header-padding-y $modal-header-padding-x;
      border-bottom: 1px solid $modal-border-color;
      .btn-close{
        border: none;
        background: transparent;
        font-size: 32px;
        font-weight: 400;
        color: $white;
      }
    }
    .modal-body{
      padding: $modal-body-padding-y $modal-body-padding-x;
      border-bottom: 1px solid $modal-border-color;
    }
    .modal-footer{
      padding: $modal-footer-padding-y $modal-footer-padding-x;
      @media(max-width: 400px) {
        padding-left: .25rem;
        padding-right: .25rem;
        flex-direction: column;
        >:not(:last-child) {
          margin-right: 0;
          margin-bottom: .7rem;
        }
        >:not(:first-child) {
          margin-left: 0;
        }
      }
    }
  }
}