/* Bootstrap Alerts */

.alert {
  font-size: $default-font-size;
  padding: 0.4rem 1rem;
  i {
    font-size: 1.25rem;
    margin-right: 1.25rem;
    vertical-align: middle;
    line-height: .5;
  }
}


